import { gql } from 'urql';

export const createProjectMutation = (s: string) => gql`
    mutation createProject($newProjectData: NewProjectInput!, $primaryUserData: PrimaryUserInput!){
        createProject(newProjectData: $newProjectData, primaryUserData: $primaryUserData) {
            ${s}
        }
    }
`;

export const removeProjectMutation = gql`
	mutation removeProject($id: Int!) {
		removeProject(id: $id)
	}
`;

export const updateProjectMutation = gql`
	mutation updateProject($existingProjectData: ExistingProjectInput!) {
		updateProject(existingProjectData: $existingProjectData) {
			id
		}
	}
`;

export const updateProjectSettingsMutation = gql`
	mutation updateProjectSettings(
		$existingProjectData: ExistingProjectSettingsInput!
	) {
		updateProjectSettings(existingProjectData: $existingProjectData) {
			id
		}
	}
`;

export const updateProjectStyleMutation = gql`
	mutation updateProjectStyle(
		$existingProjectData: ExistingProjectStyleInput!
	) {
		updateProjectStyle(existingProjectData: $existingProjectData) {
			id
		}
	}
`;

export const inviteUserToProjectMutation = gql`
	mutation inviteUserToProject(
		$userData: UserToProjectInput!
		$projectId: Int!
	) {
		inviteUserToProject(userData: $userData, projectId: $projectId) {
			id
		}
	}
`;

export const addUserToProjectMutation = gql`
	mutation addUserToProject($userId: Int!, $projectId: Int!) {
		addUserToProject(userId: $userId, projectId: $projectId)
	}
`;

export const removeUserFromProjectMutation = gql`
	mutation removeUserFromProject($userId: Int!, $projectId: Int!) {
		removeUserFromProject(userId: $userId, projectId: $projectId)
	}
`;

export const updateDeveloperDetailsMutation = gql`
	mutation updateDeveloperDetails(
		$existingDeveloperData: ExistingDeveloperInput!
	) {
		updateDeveloperDetails(existingDeveloperData: $existingDeveloperData)
	}
`;

export const toggleDailyDigestToProjectMutation = gql`
	mutation toggleDailyDigest(
		$userId: Int!
		$projectId: Int!
		$enable: Boolean!
	) {
		toggleDailyDigest(userId: $userId, projectId: $projectId, enable: $enable)
	}
`;

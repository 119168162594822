import React, { FC, useState } from 'react';
import { Box, Card, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { Can } from 'providers/CaslAbilityProvider';
import { permissionAction, permissionSubject } from 'utility/defineAbilityFor';
import { Project } from 'types/types';
import { getLaunchedDate } from 'utility/date';
import useProjectSettings from '../hooks/useProjectSettings';
import settingOptions from './settingOptions';
import SettingsTabInfo from './SettingsTabInfo';
import UnlaunchedCard from './UnlaunchedCard';
import OptionCard from './OptionCard';
import GoogleAnalyticsForm from '../GoogleAnalyticsForm';
import { Cell, Grid } from 'styled-css-grid';
import dayjs from 'dayjs';
import RegularButton from '../../../common/styled/RegularButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackBar } from '../../../../providers/SnackBarProvider';
import { useUserContext } from '../../../../providers/UserProvider';

interface ProjectDetailsSettingsProps {
	initialValues: Project;
	onSubmitClick: (values: any) => void;
}

const enableDateKeys = [
	'translationEnabledDate',
	'customUrlEnabledDate',
	'emailSandboxEnabledDate',
	'smsSubscribeEnableDate',
];

const ProjectDetailsSettings: FC<ProjectDetailsSettingsProps> = (props) => {
	const { initialValues, onSubmitClick } = props;
	const {
		project,
		setProject,
		handlePublish,
		handleGaUpdate,
	} = useProjectSettings(initialValues, onSubmitClick);
	const { launchedDate } = project;
	const { getAccessTokenSilently: getToken } = useAuth0();
	const { notify } = useSnackBar();
	const [disabled, setDisabled] = useState<boolean>(false);
	const { user } = useUserContext();
	const handleExecuteAiInsights = async () => {
		const token = await getToken();
		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/processor/execute-ai-insights/${project.id}/${user!.id}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);
		setDisabled(true);
		notify('Processing AI Insights. You will receive an email when complete');
	};
	if (!launchedDate)
		return (
			<Grid columns={'1fr 20px 1fr'}>
				<Cell>
					<UnlaunchedCard handlePublish={handlePublish} />
				</Cell>
				<Cell />
				<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
					<Cell>
						<GoogleAnalyticsForm
							initialValues={project}
							onSubmitClick={handleGaUpdate}
						/>
					</Cell>
				</Can>
			</Grid>
		);
	return (
		<>
			<SettingsTabInfo />

			<Card
				sx={(theme) => ({
					padding: theme.spacing(3),
					display: 'block',
					backgroundColor: theme.customPalette.surface.light,
				})}
			>
				<Grid columns={'1fr 20px 500px'}>
					<Cell width={3}>
						{launchedDate && (
							<Box
								sx={(theme) => ({
									paddingLeft: theme.spacing(3),
									paddingRight: theme.spacing(3),
									paddingBottom: theme.spacing(2),
									display: 'block',
								})}
							>
								Site Launched <strong>{getLaunchedDate(launchedDate)}</strong>
							</Box>
						)}
					</Cell>
					<Cell>
						<Card
							sx={(theme) => ({
								paddingLeft: theme.spacing(3),
								paddingRight: theme.spacing(3),
								paddingBottom: theme.spacing(2),
								display: 'block',
							})}
						>
							<h4>General Site Settings</h4>
							{settingOptions.map(
								({ name, label, miniCardLabel, description }) => {
									const value = project[name];
									const miniCardValueKey =
										name === 'translationEnabledDate'
											? 'localizeProjectKey'
											: name === 'customUrlEnabledDate'
												? 'customUrl'
												: name === 'emailSandboxEnabledDate'
													? 'emailSandbox'
													: 'smsSubscribeMobileNumber';
									const miniCardValue = project[miniCardValueKey];

									return (
										<div key={name} style={{ marginTop: 10 }}>
											<Can
												I={permissionAction.MANAGE}
												a={permissionSubject.ALL}
											>
												<Grid columns={'1fr auto'} rows={'auto 1fr'}>
													<FormControlLabel
														label={label}
														control={
															<Switch
																onChange={async (
																	e: React.ChangeEvent<HTMLInputElement>,
																) => {
																	const today = new Date();
																	today.setHours(12, 0, 0, 0);
																	await handlePublish(
																		name,
																		e.target.checked
																			? today.toISOString()
																			: null,
																	);
																}}
																checked={!!value}
															/>
														}
														labelPlacement="end"
													/>

													<i>
														{value && (
															<>Updated : {dayjs(value).format('MM/DD/YYYY')}</>
														)}
													</i>

													<Typography
														variant="body2"
														pb={2}
														color="secondary"
														sx={{ gridColumn: 'span 2', ml: '38px' }}
													>
														{description}
													</Typography>
												</Grid>

												{enableDateKeys.includes(name) && value && (
													<OptionCard
														value={miniCardValue}
														label={miniCardLabel}
														name={name}
														setProject={setProject}
														handlePublish={handlePublish}
														initialValue={initialValues[miniCardValueKey]}
														valueKey={miniCardValueKey}
													/>
												)}
											</Can>
										</div>
									);
								},
							)}
							{/* meta description (input has different rules) */}
							<Card
								sx={{
									padding: 2,
									marginBottom: 4,
									display: 'flex',
									alignItems: 'center',
									columnGap: 2,
								}}
							>
								<TextField
									id='metaDescription'
									label='SEO Meta Description'
									variant="outlined"
									size="small"
									fullWidth
									multiline
									inputProps={{ maxlength: 155 }}
									value={project.metaDescription}
									onChange={(e) =>
										setProject((project) => ({
											...project,
											metaDescription: e.target.value,
										}))
									}
								/>
								<RegularButton
									disabled={!project.metaDescription || project.metaDescription === initialValues.metaDescription}
									onClick={() => handlePublish('metaDescription', project.metaDescription!)}
								>
									Save
								</RegularButton>
							</Card>
							<FormControlLabel
								label="Show Feeback Count"
								control={
									<Switch
										onChange={async (
											e: React.ChangeEvent<HTMLInputElement>,
										) => {
											await handlePublish(
												'showFeedbackCount',
												e.target.checked
											);
										}}
										checked={project.showFeedbackCount!}
									/>
								}
								labelPlacement="end"
							/>
							<div style={{ marginTop: 10 }}>
								<Can
									I={permissionAction.MANAGE}
									a={permissionSubject.ALL}
								>
									<Grid columns={'1fr auto'} rows={'auto 1fr'}>
										<FormControlLabel
											label='Enable AI Insights'
											control={
												<Switch
													onChange={async (
														e: React.ChangeEvent<HTMLInputElement>,
													) => {
														const today = new Date();
														today.setHours(12, 0, 0, 0);
														await handlePublish(
															'aiInsightsEnabledDate',
															e.target.checked
																? today.toISOString()
																: null,
														);
													}}
													checked={!!project.aiInsightsEnabledDate}
												/>
											}
											labelPlacement="end"
										/>

										<i>
											{project.aiInsightsEnabledDate && (
												<>Updated : {dayjs(project.aiInsightsEnabledDate).format('MM/DD/YYYY')}</>
											)}
										</i>

										<Typography
											variant="body2"
											pb={2}
											color="secondary"
											sx={{ gridColumn: 'span 2', ml: '38px' }}
										>
											Enable AI Insights for this site so that clients can view AI powered insights into their user's comments.
										</Typography>
										{project.aiInsightsEnabledDate && (
											<Card
												sx={{
													padding: 2,
													marginBottom: 4,
													display: 'flex',
													alignItems: 'flex-start',
													rowGap: 2,
													flexDirection: 'column'
												}}
											>
												<RegularButton disabled={disabled} onClick={handleExecuteAiInsights} variant="contained">
													Execute AI Insights
												</RegularButton>

												<i>Last Run Date: {project.aiInsightsLastExecutionDate ? dayjs(project.aiInsightsLastExecutionDate).format('MM/DD/YYYY') : 'N/A'}</i>
												<i>Cost of Last Run: {project.aiInsightsCost ? `$${project.aiInsightsCost.toFixed(6)}` : 'N/A'}</i>

												<FormControlLabel
													label='Enable Auto AI Insights'
													control={
														<Switch
															onChange={async (
																e: React.ChangeEvent<HTMLInputElement>,
															) => {
																const today = new Date();
																today.setHours(12, 0, 0, 0);
																await handlePublish(
																	'autoAiInsightsEnabledDate',
																	e.target.checked
																		? today.toISOString()
																		: null,
																);
															}}
															checked={!!project.autoAiInsightsEnabledDate}
														/>
													}
													labelPlacement="end"
												/>

												<i>
													{project.autoAiInsightsEnabledDate && (
														<>Updated : {dayjs(project.autoAiInsightsEnabledDate).format('MM/DD/YYYY')}</>
													)}
												</i>
											</Card>
										)}
									</Grid>
								</Can>
							</div>
						</Card>
					</Cell>
					<Cell />
					<Cell>
						<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
							<Grid columns={'1fr'} gap={'20px'}>
								<Cell>
									<GoogleAnalyticsForm
										initialValues={project}
										onSubmitClick={handleGaUpdate}
									/>
								</Cell>
							</Grid>
						</Can>
					</Cell>
				</Grid>
			</Card>
		</>
	);
};

export default ProjectDetailsSettings;

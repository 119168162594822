import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { MuiColorInput } from 'mui-color-input';
import * as Yup from 'yup';
import { hex } from 'wcag-contrast';

interface ColorThemesFormProps {
  colors: {
    primaryActionColor: string;
    headlineTextColor: string;
    secondaryActionColor: string;
    backgroundTextColor: string;
    overlayBackgroundColor: string;
  };
  onSubmit: (values: ColorThemesFormProps['colors']) => void;
  onReset: () => void;
  isPaused: boolean;
}

const MIN_CONTRAST_RATIO = 4.5;

const validationSchema = Yup.object({
  primaryActionColor: Yup.string()
    .matches(/^#[0-9A-Fa-f]{6}$/, 'Must be a valid hex color')
    .required('Required')
    .test('contrast', 'Color Contrast must meet WCAG 1.4.3 minimum.', (value) =>
      value ? hex('#FFFFFF', value) >= MIN_CONTRAST_RATIO : false
    ),
  headlineTextColor: Yup.string()
    .matches(/^#[0-9A-Fa-f]{6}$/, 'Must be a valid hex color')
    .required('Required')
    .test('contrast', 'Color Contrast must meet WCAG 1.4.3 minimum.', (value) =>
      value ? hex(value, '#FFFFFF') >= MIN_CONTRAST_RATIO : false
    ),
  secondaryActionColor: Yup.string()
    .matches(/^#[0-9A-Fa-f]{6}$/, 'Must be a valid hex color')
    .required('Required')
    .test('contrast', 'Color Contrast must meet WCAG 1.4.3 minimum.', (value) =>
      value ? hex('#FFFFFF', value) >= MIN_CONTRAST_RATIO : false
    ),
  backgroundTextColor: Yup.string()
    .matches(/^#[0-9A-Fa-f]{6}$/, 'Must be a valid hex color')
    .required('Required')
    .test('contrast', 'Color Contrast must meet WCAG 1.4.3 minimum.', (value) =>
      value ? hex('#000000', value) >= MIN_CONTRAST_RATIO : false
    ),
  overlayBackgroundColor: Yup.string()
    .matches(/^#[0-9A-Fa-f]{8}$/, 'Must be a valid hex color')
    .required('Required')
    .test('contrast', 'Color Contrast must meet WCAG 1.4.3 minimum.', (value) =>
      value ? hex('#FFFFFF', value) >= MIN_CONTRAST_RATIO : false
    ),
});

const LABELS: Record<keyof ColorThemesFormProps['colors'], string> = {
  primaryActionColor: 'Color 1: Used for primary buttons and links',
  headlineTextColor: 'Color 2: Used for headline text',
  secondaryActionColor: 'Color 3: Used for secondary calls to action, feedback section, and footer sections',
  backgroundTextColor: 'Color 4: Used for question text background in the feedback page',
  overlayBackgroundColor: 'Color 5: Used for the overlay background color and opacity on images and hero images',
};

const getExample = (name: keyof ColorThemesFormProps['colors'], value: string | number): React.ReactNode => {
  switch (name) {
    case 'primaryActionColor':
      return (
        <Button
          variant="contained"
          sx={{
            backgroundColor: value,
            color: '#FFFFFF',
            borderRadius: '20px',
            padding: '5px 10px',
          }}
        >
          example
        </Button>
      );
    case 'headlineTextColor':
      return (
        <Typography
          variant="h6"
          sx={{
            color: value,
            fontWeight: '900',
          }}
        >
          Example Headline
        </Typography>
      );
    case 'secondaryActionColor':
      return (
        <Box
          sx={{
            backgroundColor: value,
            color: '#FFFFFF',
            borderRadius: '8px',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 900,
            fontSize: '20px',
          }}
        >
          Example
        </Box>
      );
    case 'backgroundTextColor':
      return (
        <Box
          sx={{
            backgroundColor: value,
            color: '#000000',
            borderRadius: '8px',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 400,
            fontSize: '16px',
          }}
        >
          Example
        </Box>
      );
    case 'overlayBackgroundColor':
      return (
        <Box
          sx={{
            backgroundColor: value,
            color: '#FFFFFF',
            borderRadius: '4px',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            textAlign: 'center',
            fontWeight: 400,
            fontSize: '16px',
          }}
        >
        Example
      </Box>
      );
    default:
      return null;
  }
};

const ColorThemesForm: React.FC<ColorThemesFormProps> = ({ colors, onSubmit, onReset, isPaused }) => {
  return (
    <>
      <h4>Color Themes</h4>
      <Typography variant="body2" pb={2}>
        All colors must pass minimum web accessibility color contrast standards.
      </Typography>
      <Formik
        initialValues={colors}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, isSubmitting, resetForm }) => (
          <Form>
            {Object.keys(values).map((key) => {
              const name = key as keyof ColorThemesFormProps['colors'];
              return (
                <Box key={name} mb={3}>
                  <Typography variant="body2" gutterBottom pb={1}>
                    {LABELS[name]}
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Field name={name}>
                        {({ field, form }: { field: { name: keyof ColorThemesFormProps['colors']; value: string }, form: any }) => (
                          <MuiColorInput
                            isAlphaHidden={key === 'overlayBackgroundColor' ? false : true}
                            label="Hex value"
                            format={key === 'overlayBackgroundColor' ? 'hex8' : 'hex'}
                            value={field.value}
                            onChange={(color: string) => form.setFieldValue(field.name, color)}
                            error={touched[field.name] && !!errors[field.name]}
                            helperText={touched[field.name] && errors[field.name] ? String(errors[field.name]) : ''}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item>{getExample(name, values[name])}</Grid>
                  </Grid>
                </Box>
              );
            })}
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button variant="outlined" color="primary" onClick={() => {
                onReset();
                resetForm();
              }}>
                Revert to Default
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || isPaused}>
                Save Changes
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ColorThemesForm;

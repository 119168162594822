import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ColorThemesForm from './ColorThemesForm';
import StyleSettingsForm from './StyleSettingsForm';
import { ExistingProjectStyleInput, Project } from '../../../../types/types';
import { usePausedContext } from '@coUrbanize/community-modules';
import { useSaveActionConfirmation } from '../../../../hooks/useActionConfirmation';
import { useSnackBar } from '../../../../providers/SnackBarProvider';
import { useMutation } from 'urql';
import { updateProjectStyleMutation } from '../../../../api/mutation/project.mutation';

// Define the types for the form values
interface FormValues {
  primaryActionColor: string;
  headlineTextColor: string;
  secondaryActionColor: string;
  backgroundTextColor: string;
  overlayBackgroundColor: string;
}

export const DEFAULT_COLOR_PALETTE: FormValues = {
  primaryActionColor: '#0076a0',
  headlineTextColor: '#004057',
  secondaryActionColor: '#00645F',
  backgroundTextColor: '#D0E2E2',
  overlayBackgroundColor: '#0076a0B2',
};

const StyleSettingsPage: React.FC<{ project: Project }> = ({ project }) => {

  const { isPaused } = usePausedContext();
	const saveActionConfirmation = useSaveActionConfirmation();
  const { notify } = useSnackBar();

  const [, updateProjectStyle] = useMutation(updateProjectStyleMutation);
  
// Updated useCallback to transform overlayBackgroundColor into hex8 format
const getInitialColors = useCallback((): FormValues => ({
  primaryActionColor: project?.primaryActionColor ?? DEFAULT_COLOR_PALETTE.primaryActionColor,
  headlineTextColor: project?.headlineTextColor ?? DEFAULT_COLOR_PALETTE.headlineTextColor,
  secondaryActionColor: project?.secondaryActionColor ?? DEFAULT_COLOR_PALETTE.secondaryActionColor,
  backgroundTextColor: project?.backgroundTextColor ?? DEFAULT_COLOR_PALETTE.backgroundTextColor,
  overlayBackgroundColor:  project?.overlayBackgroundColor ?? DEFAULT_COLOR_PALETTE.overlayBackgroundColor,
}), [project]);

  const [colors, setColors] = useState<FormValues>(getInitialColors);
  
  useEffect(() => {
    setColors(getInitialColors());
  }, [getInitialColors]);

  const handleColorUpdate = async (updatedColors: FormValues) => {
    setColors(updatedColors);
    const updatedProject: Partial<ExistingProjectStyleInput> = {
      id: project.id,
      ...updatedColors,
    };
    
    await saveActionConfirmation(
      {
        title: 'Site Color Change Confirmation',
        message: `Are you sure you want to change the public site colors for ${project.name}?`,
        confirmLabel: 'Proceed anyway',
      },
      async () => {
        const res = await updateProjectStyle({ existingProjectData: updatedProject });
        if (res?.error) return notify('Fail to update color settings', 'error');
        return notify('Color settings updated successfully');
      },
    );

  };

  const handleStyleUpdate = async (font: string) => {
    await saveActionConfirmation(
      {
        title: 'Site Font Change Confirmation',
        message: `Are you sure you want to change the public site font from ${project.siteFont} to ${font}?`,
        confirmLabel: 'Proceed anyway',
      },
      async () => {
        const res = await updateProjectStyle({
          existingProjectData: {id: project.id!, siteFont: font},
        });
    
        if (res?.error) return notify('Fail to update style settings', 'error');
        return notify('Style settings updated successfully');
      },
    );

	};

  const handleResetColors = () => {
    setColors(DEFAULT_COLOR_PALETTE);
  };

  return (
    <Grid container spacing={2}>
      {/* First Column */}
      <Grid item xs={12} md={6}>
        <ColorThemesForm colors={colors} onSubmit={handleColorUpdate} onReset={handleResetColors} isPaused={isPaused} />
      </Grid>

      {/* Second Column */}
      <Grid item xs={12} md={6}>
        <StyleSettingsForm font={project.siteFont?? 'Nunito'} onSubmitClick={handleStyleUpdate} isPaused={isPaused} />
      </Grid>
    </Grid>
  );
};

export default StyleSettingsPage;
